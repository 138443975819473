export default [
  {
    path: '/manage-prayers',
    name: 'manage-prayers',
    component: () => import('@/views/prayers/prayers-list/Prayers.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/organization-dash/update-azan',
    name: 'update-azan',
    component: () => import('@/views/pages/others/UpdateAzan.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/organization-dash',
    name: 'organization-dash',
    component: () => import('@/views/pages/others/UserDash.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/organization-dash/prayers',
    name: 'prayers-dash',
    component: () => import('@/views/pages/others/Prayers.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/organization-dash/transactions',
    name: 'prayers-dash',
    component: () => import('@/views/pages/others/Transactions.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/organization-dash/announcements',
    name: 'prayers-dash',
    component: () => import('@/views/pages/others/Announcements.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/organization-dash/update-iqama',
    name: 'update-iqama',
    component: () => import('@/views/pages/others/UpdateIqama.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/organization-dash/import-export',
    name: 'import-export',
    component: () => import('@/views/pages/others/PrayerImportExport.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
