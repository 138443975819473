export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/organizations/organization-list/test.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
