import axios from 'axios'
export default {
  namespaced: true,
  state: {
    data: []
  },
  getters: {},
  mutations: {
    GET_DATA(state, data) {
      state.data = data
    }
  },
  actions: {
    provider({ commit }, { tableConfigs  }) {
      const promis = axios.get('http://entities-microsevice.muslimdo.com/api/internalops/entity/list/Organization/' + tableConfigs.currentPage)
      return promis.then(res => {
        commit('GET_DATA', res.data.data)
      // eslint-disable-next-line no-unused-vars
      })
    },
  },
}
