import axios from 'axios'

export default {
  namespaced: true,
  state: {
    data: [],
  },
  getters: {},
  mutations: {
    SET_DATA(state, data) {
      state.data = data
    }
  },
  actions: {
    getData({commit}) {
      axios.get('https://fakestoreapi.com/products/').then(res => {
        commit('SET_DATA', res.data)
      })
    }
  },
}
