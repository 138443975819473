import Vue from 'vue'
import VueRouter from 'vue-router'
import dashboard from './routes/dashboard'
import clients from './routes/clients'
import prayers from './routes/prayers'
// import prayerAndBooking from './routes/prayer-and-booking'
import auth from './routes/auth'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    ...dashboard,
    ...auth,
    ...clients,
    ...prayers,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    if (localStorage.getItem('access_token')) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
