export default [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/users/users-list/users.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/organizations',
    name: 'organizations',
    component: () => import('@/views/organizations/organization-list/organizations.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/organizations/add',
    name: 'add-inpo',
    component: () => import('@/views/organizations/organization-list/add-inpo/InpoListAddNew.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/organizations/edit/:id',
    name: 'inpo-edit',
    component: () => import('@/views/organizations/organization-list/edit-inpo/InpoListEdit.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/commercial',
    name: 'business',
    component: () => import('@/views/business/buisiness-list/Commercial.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/commercial/add',
    name: 'add-commercial',
    component: () => import('@/views/business/buisiness-list/add-commercial/CommercialListAddNew.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/commercial/edit/:id',
    name: 'edit-commercial',
    component: () => import('@/views/business/buisiness-list/edit-commercial/CommercialListEdit.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/organizations/view/:id',
    name: 'view-organization',
    component: () => import('@/views/organizations/organization-view/view.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/business/view/:id',
    name: 'view-business',
    component: () => import('@/views/business/business-view/view.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/users/view/:id',
    name: 'view-user',
    component: () => import('@/views/users/users-view/view.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/announcecments',
    name: 'announcements',
    component: () => import('@/views/announcements/list-announements/Announcements.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/announcecments/add',
    name: 'add-announce',
    component: () => import('@/views/announcements/add-announcement/AddAnnounce.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/announcecments/view/:id',
    name: 'view-announce',
    component: () => import('@/views/announcements/view-announce/ViewAnnounce.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/announcecments/edit/:id',
    name: 'edit-announce',
    component: () => import('@/views/announcements/edit-announcement/EditAnnounce.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tags',
    name: 'tags',
    component: () => import('@/views/pages/others/others/tags/Tags.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/languages',
    name: 'languages',
    component: () => import('@/views/pages/others/others/languages/Languages.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/classifications',
    name: 'classifications',
    component: () => import('@/views/pages/others/others/classifications/Classifications.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/subclassifications',
    name: 'sub-classifications',
    component: () => import('@/views/pages/others/others/sub-classifications/SubClassifications.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/donation-categories',
    name: 'donation-categories',
    component: () => import('@/views/pages/others/others/donation-categories/DonationCategories.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/booking-prayer',
    name: 'prayer-booking',
    component: () => import('@/views/prayers-management/booking-prayers/booking-prayers-list/BookingPrayersList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/booking-prayer/add',
    name: 'add-prayer-booking',
    component: () => import('@/views/prayers-management/booking-prayers/booking-prayers-add/AddBookingPrayers.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/booking-prayer/edit/:id',
    name: 'edit-prayer-booking',
    component: () => import('@/views/prayers-management/booking-prayers/booking-prayers-edit/BookingPrayersEdit.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/live-management',
    name: 'live-management',
    component: () => import('@/views/live-management/live-list/ListLive.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/live-management/add',
    name: 'add-live',
    component: () => import('@/views/live-management/live-add/AddLive.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
